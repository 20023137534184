body {
  margin: 0;


   scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x:hidden ;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.carousel-item > img {
  width: 75%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.carousel-caption h3 {
font-size:      2em;
color:          black !important;

}

/* Description text */
.carousel-caption p {
  padding: 0% !important;

  position:relative;

  height: 10%;

  right: 20%;
  width: 35%;


  font-size:      1.5em;
  color:          black !important;
  background-color: gray;
  background-size: 8em

}
.container-fuild{
  padding-left: 0 !important;
  padding-right: 0 !important; 
}
.block-example {
    width: 100%;
    height: 0.0625rem;
    margin: 0.5rem;
    background-color: #FFFFFF;
    display: inline-block;
}
a{
  text-decoration: none !important;
}