/* fixed social*/
#fixed-social {
  position: fixed;
  top: 130px;
  right: 0px;
}
.background-wrok {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 111111;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
}

#fixed-social a {
  color: #fff;
  display: block;
  height: 40px;
  position: relative;
  text-align: center;
  line-height: 40px;
  width: 40px;
  margin-bottom: 1px;
  z-index: 2;
}
#fixed-social a:hover > span {
  visibility: visible;
  right: 41px;
  opacity: 1;
}
#fixed-social a span {
  line-height: 40px;
  right: 60px;
  position: absolute;
  text-align: center;
  width: 120px;
  visibility: hidden;
  transition-duration: 0.5s;
  z-index: 1;
  opacity: 0;
}
.fixed-facebook {
  background-color: #657fd5;
}
.fixed-facebook span {
  background-color: #3974c0;
}
.fixed-twitter {
  background-color: #2ba4d9;
}
.fixed-twitter span {
  background-color: #2ba4d9;
}
.fixed-gplus {
  background-color: #00af54;
}
.fixed-youtube {
  background-color: #ff0000;
}
.fixed-youtube span {
  background-color: #ff0000;
}
.fixed-gplus span {
  background-color: #00af54;
}
.fixed-linkedin {
  background-color: #ffc41e;
}
.fixed-linkedin span {
  background-color: #ffc41e;
}
.fixed-instagrem {
  background-color: #3f729b;
}
.fixed-instagrem span {
  background-color: #3f729b;
}
.fixed-tumblr {
  background-color: #eb1471;
}
.fixed-tumblr span {
  background-color: #eb1471;
}
/*end fixed social*/

/* start of scrollbar*/
.container-fluid{
  scrollbar-color: #0A4C95 #C2D2E4;
  
}

:root{
  scrollbar-color: rgb(24, 24, 24) rgb(255, 255, 255) !important;
  scrollbar-width: thin !important;
}
*::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  scrollbar-width: 10px;
}
*::-webkit-scrollbar-track {
  background-color: #e1e1e1;

}
*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgb(15, 16, 16);
  scrollbar-color:rgb(15, 16, 16) #322 ;
}
html{
  
  scroll-behavior: smooth;
}
.dropdown-div{
  width: 100%;
  height: 300;


}
.label-div:nth-of-type(3){
  padding-bottom: 10px  !important;
}

.selectMenu {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.selectMenu select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  padding: 20px;

  margin: 20px;
  overflow-x: hidden;
  border: 2px solid aliceblue !important;
  background: #2c3e50;
  background-image: none;
}
/* Remove IE arrow */
select::-ms-expand {
  display: none;
}
/* Custom Select */
.select {
  position: relative;
  /* display: flex; */
  width: 15em;
  margin: 6px;
  height: 3em;
  line-height: 3;
  background: #ffffff;
  overflow: hidden;
  border-radius: 0.25em;
}
.react-select {
  display: inline-block;
  width: 15em;
  margin: 6px;
  height: 2em;
  line-height: 1;
  background: #ffffff;
  border-radius: 0.25em;
}
.colorNavlink{
  color:#111a11;
}
.selectMenu select {
  flex: 1 1;
  padding: 0 0.5em;
  color: #fff;
  cursor: pointer !important;
}

/* Arrow */
.select::after {
  content: "\25BC";
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 1em;
  background: #ffffff;
  cursor: pointer;
  pointer-events: none;
  -webkit-transition: 0.25s all ease;
  -o-transition: 0.25s all ease;
  transition: 0.25s all ease;
}

.text {
  font-family: "Roboto";
  font-weight: 300 !important;
  text-align: justify;
  line-height: 1.3 !important;
}

/* Transition */
.select:hover::after {
  color: #f39c12;
}
.MuiLink-underlineHover:hover {
  /* background-color: #2c3e50; */
  color: #ffffff;
}
.thefont {
  font-weight: 300 !important;
}

.nav-link:hover {
  color: #000000 !important;
}
.active {
  color: #0000ffe7 !important;
  /* border-bottom: 1px solid rgb(12, 12, 12); */
}
.dropdown-item {
  color: #3974c0 !important;
  font-weight: 600;

  padding: 0.25rem 0.5rem;
}

.color-link{
  color: #1d1d1d !important;
}
#themenu {
  width: 100%;
  height: 200px;
  display: flex;
  text-align: center;

  justify-content: center !important;
  padding-top: 4rem;
  align-items: center !important;
}
form button {
  text-align: center;
  display: inline;
  box-shadow: 2px -2px skyblue;
  padding-top: 1rem;
}
.cust-select{
  font-size: 0.6rem;
  color: #ffffffd2;
display: block;
margin:0;
text-align: left;
padding-left: 10px;
padding-top: 10px;

}
.css-1wa3eu0-placeholder{
  color: rgb(7, 6, 6) !important;
}
.css-1uccc91-singleValue{
  padding: 1px;
}
/* input::placeholder{
  color: rgb(7, 6, 6) !important;

} */
.selectMenuSubmit {
  background: #2db1ee;

  /* 
    background-image: -webkit-linear-gradient(top, #000000, #5d9df0);
    background-image: -moz-linear-gradient(top, #000000, #ffffff);
    background-image: -ms-linear-gradient(top, #000000, #ffffff);
    background-image: -o-linear-gradient(top, #000000, #ffffff);
    background-image: linear-gradient(to bottom, #93bce8, #ffffff, 0.5);  */
  -webkit-border-radius: 14;
  -moz-border-radius: 14;
  border-radius: 14px;
  -webkit-box-shadow: 5px 6px 3px #ffffff;
  -moz-box-shadow: 5px 6px 3px #ffffff;
  box-shadow: 5px 6px 3px #ffffff;
  /* font-family: Arial; */
  color: #962789;
  /* font-size: 12px; */
  padding: 11px 23px 11px 24px;

  text-decoration: none;
  /* text-align: center;
    display: inline-block;
    background:    #34495e;
  margin: 0 auto;
  box-shadow: 39px 36px 44px 18px rgba(28,142,143,0.57);
  -webkit-box-shadow: 39px 36px 44px 18px rgba(28,142,143,0.57);
  -moz-box-shadow: 39px 36px 44px 18px rgba(28,142,143,0.57);
    padding:10px 20px; */
  color: #000000;
  font-weight: normal;
  /* margin:  15px; */
  border-radius: 5px;
}

.selectMenuSubmit {
  background: #3fb1f5;
  /* background-image: -webkit-linear-gradient(top, #3fb1f5, #1e62d0);
  background-image: -moz-linear-gradient(top, #3fb1f5, #1e62d0);
  background-image: -ms-linear-gradient(top, #3fb1f5, #1e62d0);
  background-image: -o-linear-gradient(top, #3fb1f5, #1e62d0);
  background-image: -webkit-gradient(to bottom, #3fb1f5, #1e62d0); */
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  /* height: 19px; */
  color: #000000;
  width: 114px;

  font-size: 12px;
  font-weight: 400;
  padding: 13px;
  -webkit-box-shadow: 1px 1px 20px 0 #000000;
  -moz-box-shadow: 1px 1px 20px 0 #000000;
  box-shadow: 1px 1px 20px 0 #000000;
  border: solid #337fed 1px;
  text-decoration: none;
  /* display: inline-block; */
  cursor: pointer;
  text-align: center;
}

/* .BUTTON_YGX:hover {
    border: solid #337FED 1px;
    background: #1E62D0;
    background-image: -webkit-linear-gradient(top, #1E62D0, #3D94F6);
    background-image: -moz-linear-gradient(top, #1E62D0, #3D94F6);
    background-image: -ms-linear-gradient(top, #1E62D0, #3D94F6);
    background-image: -o-linear-gradient(top, #1E62D0, #3D94F6);
    background-image: -webkit-gradient(to bottom, #1E62D0, #3D94F6);
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    text-decoration: none;
 } */
.selectMenuSubmit:hover {
  background: #3fb2f5ea;
  /* background-image: -webkit-linear-gradient(top, #ffffff, #000000);
  background-image: -moz-linear-gradient(top, #ffffff, #000000);
  background-image: -ms-linear-gradient(top, #ffffff, #000000);
  background-image: -o-linear-gradient(top, #ffffff, #000000); */
  /* background-image: linear-gradient(to bottom,  #3fb1f5, #215fb1); */
  text-decoration: none;
}
.selectMenuSubmit:disabled {
  cursor: not-allowed;
}
.themaile {
  text-align: right;
}
.dropdown .show{
  transform: translate3d(-35px, 36px, 0px) !important;
}
.thename{
  padding-top: 1rem !important;
}
@media only screen and (max-width: 600px) {
  /* form{
      margin-top: 250px;
    } */
   .dropdown-div{
    
     height: 500px;
   }
  .selectMenuSubmit {
    display: block;
    
  } 
  
  #themenu {
    margin: 20px 0;
    align-items: center;
  }
  /* .react-select:last-of-type{
    margin-bottom: 20px;
  } */
  .themaile {
    text-align: left !important;
  }
  
  .footer-link{
    margin-left: 40px;
  }
  .thename {
    font-size: 2rem !important;
    margin-bottom: 1rem !important;
  }
  .selectMenu select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    /* padding: 20px; */

    margin: 2px;
    border: 2px solid aliceblue !important;
    background: #ffffff;
    background-image: none;
  }
}
.dropdown-item:hover {
  background-color: #3974c0;
  color: #fff !important;
}
.dropdownOpen:hover {
  background: #444;
}
 .sub-header{

 color: #fff;
 
 font-size:0.9rem;
text-align:center;
 font-weight: 400;
  line-height: 1;
  padding: 10px 40px 0 20px}
/* Wrapper */
.icon-button {
  background-color: white;
  border-radius: 2.6rem;
  cursor: pointer;
  display: inline-block;
  font-size: 1.3rem;
  height: 2.6rem;
  line-height: 2.6rem;
  margin: 0 5px;
  position: relative;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 2.6rem;
}

/* Circle */
.icon-button span {
  border-radius: 0;
  display: block;
  height: 0;
  left: 50%;
  margin: 0;
  position: absolute;
  top: 50%;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  width: 0;
}

.icon-button:hover span {
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 2.6rem;
  margin: -1.3rem;
}

/* Icons */
.icon-button i {
  background: none;
  /* color: white; */
  height: 2.6rem;
  left: 0;
  line-height: 2.6rem;
  position: absolute;
  top: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  width: 2.6rem;
  z-index: 10;
}

.twitter span {
  background-color: #4099ff;
}
.facebook span {
  background-color: #3b5998 !important;
}
.google-plus span {
  background-color: #db5a3c;
}
.tumblr span {
  background-color: #34526f;
}
.instagram span {
  background-color: #517fa4;
}
.youtube span {
  background-color: #bb0000;
}
.pinterest span {
  background-color: #cb2027;
}

.icon-button .icon-twitter {
  color: #4099ff;
}
.icon-button .icon-facebook {
  color: #3b5998;
}
.icon-button .fa-tumblr {
  color: #34526f;
}
.icon-button .icon-google-plus {
  color: #db5a3c;
}
.icon-button .fa-instagram {
  color: #517fa4;
}
.icon-button .fa-youtube {
  color: #bb0000;
}
.icon-button .fa-pinterest {
  color: #cb2027;
}

.icon-button:hover .icon-twitter,
.icon-button:hover .icon-facebook,
.icon-button:hover .icon-google-plus,
.icon-button:hover .fa-tumblr,
.icon-button:hover .fa-instagram,
.icon-button:hover .fa-youtube,
.icon-button:hover .fa-pinterest {
  color: white;
}

@media all and (max-width: 680px) {
  .icon-button {
    border-radius: 1.6rem;
    font-size: 0.8rem;
    height: 1.6rem;
    line-height: 1.6rem;
    width: 1.6rem;
  }

  .icon-button:hover span {
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 1.6rem;
    margin: -0.8rem;
  }

  /* Icons */
  .icon-button i {
    height: 1.6rem;
    line-height: 1.6rem;
    width: 1.6rem;
  }

  .pinterest {
    /* display: none;  */
  }
}

/* loading css */
.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #ffffff;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
